<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-app-bar color="primary" tile dark flat>
      <v-toolbar-title :style="`${'width: 260px'};`" class="ml-0 px-5 d-flex">
        <v-img src="@/assets/appslogos/full/ConectaSuiteBrancoFull.png" />
      </v-toolbar-title>
    </v-app-bar>

    <PageLoading v-if="state == 'loading'" />

    <!-- Tela de atualizou com sucesso ou de link não é mais válido -->
    <div
      v-if="['success', 'error'].includes(state) || expiredToken"
      class="d-flex justify-center align-center"
      style="height: calc(100vh - 64px)"
    >
      <div class="ma-0 d-flex flex-column justify-center align-center">
        <Logo width="100" height="100" product="conecta_sign" />
        <v-card-title
          class="text-h5 font-weight-bold mb-6"
          v-if="state == 'success'"
        >
          <v-icon color="success" size="25">mdi-check-circle</v-icon>
          {{ $t("confirmUpdateTags.successfull") }}
        </v-card-title>
        <v-card-title
          class="text-h5 font-weight-bold mb-6"
          v-else-if="state == 'error'"
        >
          <v-icon color="error" size="25">mdi-close-circle</v-icon>
          {{ $t("confirmUpdateTags.invalidLink") }}
        </v-card-title>

        <v-card-actions class="justify-center">
          <v-btn
            x-large
            color="error"
            class="text-none text-body-1 font-weight-medium px-10"
            @click="openLink('https://mail.google.com/')"
          >
            <v-icon left size="25" class="mr-4">mdi-gmail</v-icon>
            {{ $t("common.backToGmail") }}
          </v-btn>
          <v-btn
            dark
            x-large
            color="primarySuite"
            class="text-none text-body-1 font-weight-medium px-10"
            @click="state = 'edit'"
          >
            <Logo
              width="28"
              height="28"
              product="conecta_suite"
              white
              class="mr-4"
            />
            {{ $t("confirmUpdateTags.editAgain") }}
          </v-btn>
        </v-card-actions>
      </div>
    </div>

    <v-stepper
      v-if="!['success', 'error'].includes(state)"
      v-model="step"
      flat
      tile
    >
      <v-row
        no-gutters
        style="background-color: var(--v-primary-base)"
        class="confirm-update-tags-and-apply elevation-0"
      >
        <v-col>
          <v-stepper-step
            step="1"
            :class="state === 'edit' ? 'background rounded-t-lg' : 'link'"
            style="height: 100%"
            @click="state = 'edit'"
          >
            <span
              :class="`${
                state === 'edit' ? 'primary--text' : 'white--text'
              } ms-2`"
            >
              {{ $t("confirmUpdateTags.updateTags") }}
            </span>
          </v-stepper-step>
        </v-col>
        <v-col>
          <v-stepper-step
            step="2"
            :class="state === 'preview' ? 'background rounded-t-lg' : 'link'"
            style="height: 100%"
            @click="state = 'preview'"
          >
            <span
              :class="`${
                state === 'preview' ? 'primary--text' : 'white--text'
              } ms-2`"
              >{{ $t("confirmUpdateTags.previewAndApply") }}
            </span>
          </v-stepper-step>
        </v-col>
      </v-row>

      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-container
            v-if="
              selectedTemplate && selectedTemplate.html !== null && userTags
            "
            class="mb-0 pb-0"
          >
            <MissingTagsWarning :selected="[userTags]" />
          </v-container>
          <UserTags
            v-if="state === 'edit' && userToUpdateTags"
            :user="userToUpdateTags"
            :userToken="$route.query.token"
            isExternalPage
            resetInputs
            showPreviewAction
            @update="updateUser"
            @preview="updatePreview"
          />
        </v-stepper-content>

        <v-stepper-content step="2" class="pa-0">
          <v-container
            v-if="
              state === 'preview' &&
              selectedTemplate &&
              selectedTemplate.html !== null
            "
          >
            <MissingTagsWarning :selected="[userTags]" />
            <v-card elevation="0">
              <v-row class="justify-center" no-gutters>
                <v-col cols="12">
                  <TemplatePreview
                    :title="$t('common.emailSignatureModelPreview')"
                    :from="$t('common.conectaSuite')"
                    :html="selectedTemplate.html"
                    :info-to-render="userTags"
                    flat
                    tile
                  />

                  <v-footer class="transparent px-5 py-4">
                    <v-btn
                      color="grey"
                      class="px-3 me-3"
                      text
                      x-large
                      @click="state = 'edit'"
                    >
                      {{ $t("common.back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      x-large
                      color="accent"
                      class="white--text"
                      :loading="loadingUserInformation"
                      @click="applyTemplate"
                    >
                      <v-icon v-text="'mdi-check'" left />
                      {{ $t("action.apply") }}
                    </v-btn>
                  </v-footer>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { openInNewTab, sleep } from "@/helpers/services/utils";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
import { fluxTemplateToPreview } from "@/helpers/services/tagPreview";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import PageLoading from "@/components/PageLoading";
import UserTags from "@/components/sign/tags/UserTags.vue";
import TemplatePreview from "@/components/sign/general/TemplatePreview.vue";
import MissingTagsWarning from "@/components/sign/general/MissingTagsWarning.vue";

export default {
  name: "ConfirmUpdateAndApply",

  components: {
    PageLoading,
    UserTags,
    TemplatePreview,
    MissingTagsWarning,
  },

  data() {
    return {
      expiredToken: false,
      loadingUserInformation: false,
      userToUpdateTags: false,
      editableData: false,
      state: "loading",
      dialogPreviewTemplate: false,
      step: 1,
    };
  },

  computed: {
    ...mapGetters(["selectedTemplate"]),

    userTags() {
      return (
        this.userToUpdateTags &&
        this.editableData && {
          ...this.userToUpdateTags,
          ...this.editableData,
        }
      );
    },
  },

  watch: {
    state() {
      if (this.state === "edit" && this.step !== "1") this.step = "1";
      else if (this.state === "preview" && this.step !== "2") this.step = "2";
    },
    step() {
      if (this.step === "1" && this.state !== "edit") this.state = "edit";
      else if (this.step === "2" && this.state !== "preview")
        this.state = "preview";
    },
  },

  methods: {
    openInNewTab,

    fluxTemplateToPreview,

    ...mapMutations([
      "setSnackBar",
      "setCurrentUser",
      "setCompany",
      "setAcceptedDriveScopes",
    ]),

    ...mapActions(["getSignatureById"]),

    openLink(link) {
      openInNewTab(link);
      window.top.close();
    },

    async getUserInformation() {
      const token = this.$route.query.token;

      let url = `${process.env.VUE_APP_API_BASE_URL}/users/user-tags-preview`;
      const params = new URLSearchParams();
      params.append("scopes", INCREMENTAL_SCOPES.DRIVE);

      await this.$axios
        .get(url, {
          headers: {
            Authorization: token,
          },
          params,
        })
        .then(({ data }) => {
          this.userToUpdateTags = data.user;
          this.setCurrentUser(data.user);
          this.setCompany(data.company);
          this.setAcceptedDriveScopes(data.accepted_drive_scope);
        })
        .catch(() => {
          this.expiredToken = true;
        });
    },

    async updateUser(payload) {
      this.loadingUserInformation = true;

      if (!Object.keys(payload.editable_data).length) {
        this.loadingUserInformation = false;
        this.state = "preview";
        return;
      }

      delete payload.id_user;

      this.state = "preview";

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/confirm-tags-update`;

      const auth = {
        headers: {
          Authorization: this.$route.query.token,
        },
      };

      await this.$axios
        .post(url, payload, auth)
        .then(() => {
          this.setSnackBar({
            message: this.$t("confirmUpdateTags.successfull"),
            show: true,
          });

          this.userToUpdateTags = {
            ...this.userToUpdateTags,
            ...payload.editable_data,
            custom_tags: {
              ...this.userToUpdateTags.custom_tags,
              ...payload.editable_data.custom_tags,
            },
          };
        })
        .catch((error) => {
          this.setSnackBar({
            message: this.$t("errorMessages.updateTags"),
            show: true,
            color: "error",
          });
          this.state = "edit";
          console.error("confirmUserUpdate(): ", error);
        })
        .finally(() => {
          this.loadingUserInformation = false;
        });
    },

    updatePreview(payload) {
      this.editableData = { ...payload.editable_data };
    },

    async getApplyingTemplate() {
      const template = this.$route.query.template;
      await this.getSignatureById(template);
    },

    async applyTemplate() {
      for (const key in this.editableData) {
        if (this.userToUpdateTags[key] != this.editableData[key]) {
          await this.updateUser({ editable_data: this.editableData });
          await sleep(1000);
          break;
        }
      }
      this.loadingUserInformation = true;
      const token = this.$route.query.token;
      const template_key = this.$route.query.template;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/apply-template?template_key=${template_key}`;

      await this.$axios
        .post(
          url,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          this.setSnackBar({
            message: this.$t("successMessages.templateApplied"),
            show: true,
          });
          this.state = "success";
        })
        .catch(({ response }) => {
          this.setSnackBar({
            message: errorMessages[response.data.code],
            show: true,
            color: "error",
          });
          this.state = "error";
        })
        .finally(() => {
          this.loadingUserInformation = false;
        });
    },
  },

  async beforeMount() {
    this.state = "loading";
    await this.getUserInformation();
    await this.getApplyingTemplate();
    this.state = "edit";
  },
};
</script>

<style>
v-toolbar__extension {
  padding: 0px !important;
}

.v-stepper__step__step,
.v-stepper__step__step.primary {
  background-color: var(--v-accent-base) !important;
  border-color: var(--v-accent-base) !important;
}

.disabled > .v-stepper__step__step {
  background-color: rgb(199, 199, 199) !important;
  border-color: grey !important;
}

@media only screen and (max-width: 959.98px) {
  .confirm-update-tags-and-apply .v-stepper__label {
    display: block !important;
  }
}
</style>
