<template>
  <Alert
    :color="hasTagsMissing ? 'warning' : 'info'"
    :dense="dense"
    text
    :expansion="hasTagsMissing"
    :action-text="hasTagsMissing ? $t('action.knowMore') : ''"
    action-icon=""
    :key="update"
  >
    <span v-html="small ? $t('missingTags.someAreInUsers') : usersLabel" />
    <template #expansion>
      <v-list dense class="mt-2 px-0">
        <v-list-item
          v-for="(tag, user) of tagsMissing"
          :key="tagsMissing[tag]"
          class="warning--text text--darken-3 px-0 mx-0"
        >
          <span class="font-weight-bold mr-1">{{ user }}: </span>
          {{ tag.join(", ") }}.
          <br />
        </v-list-item>
      </v-list>
    </template>
  </Alert>
</template>
<script>
import { getTagsMissing } from "@/helpers/services/sign";
import { mapGetters } from "vuex";

export default {
  name: "MissingTagsWarning",

  props: {
    dense: { type: Boolean, default: false },
    selected: { type: Array, required: true },
    small: { type: Boolean, default: false },
  },

  data() {
    return {
      tagsMissing: {},
      update: 0,
    };
  },

  computed: {
    ...mapGetters(["selectedTemplateNewHtml"]),

    hasTagsMissing() {
      return Object.keys(this.tagsMissing).length > 0;
    },

    usersLabel() {
      const defaultReturn = this.$t("missingTags.allIsFilled");
      const names = Object.keys(this.tagsMissing);

      if (!names.length) {
        return defaultReturn;
      }

      const usersMissingTags = Object.values(this.tagsMissing);
      const missingTagsNumber = usersMissingTags.reduce((tagsByUser, acc) => {
        tagsByUser.forEach((tag) => {
          acc.add(tag);
        });

        return acc;
      }, new Set());

      if (names.length === 1 && missingTagsNumber.length === 1) {
        return this.$t("missingTags.aUserAndATag", {
          name: names[0],
          tag: missingTagsNumber[0],
        });
      } else if (names.length === 1 && missingTagsNumber.length > 1) {
        return this.$t("missingTags.aUserAndTags", { name: names[0] });
      } else if (names.length > 1 && missingTagsNumber.length === 1) {
        return this.$t("missingTags.usersAndATag", { num: names.length });
      } else if (names.length > 1) {
        return this.$t("missingTags.usersAndTags", { num: names.length });
      } else {
        return defaultReturn;
      }
    },
  },

  watch: {
    selected() {
      this.update++;
    },

    update() {
      this.getTagsMissing();
    },
  },

  methods: {
    getTagsMissing() {
      this.tagsMissing = getTagsMissing(
        this.selected,
        this.selectedTemplateNewHtml
      );
    },
  },

  beforeMount() {
    this.getTagsMissing();
  },
};
</script>
